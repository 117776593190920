// Notifications

// 1.Config
@import '_base/_css3/components',
        '_base/mixins',
        '_base/functions',
        '_base/config',
        '_base/reset',
        '_base/fonts',
        '_base/presets';

// 2.Base
body,
html {
  background: #f7f7f7;
}
.m-notification {
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: get-brand(primary);
  }
}
.m-notification__heading {
  margin-bottom: 12px;
  font-size: 42px;
}
.m-notification__keyvisual {
  display: block;
  margin: 0 auto 20px;
  height: 292px;
  width: 272px;
  background: url(../img/404.png) no-repeat;
}

.m-notification--server-error {
  .m-notification__keyvisual {
    background-image: url(../img/500.png);
  }
}

// Startup
.m-notification--startup {
  .m-notification__keyvisual {
    height: 362px;
    opacity: .6;
    position: relative;
    width: 450px;
    background-image: url(../img/money.png);

    &:before {
      content: '';
      height: 85px;
      left: 50%;
      margin-top: 140px;
      position: absolute;
      top: 50%;
      width: 85px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: url(../img/spinner.gif) no-repeat #f7f7f7;
      background-size: 100%;
    }
  }
}

// Blocked
.m-notification--blocked {
  .m-notification__keyvisual {
    background-image: url(../img/403.png);
  }
}

// Fullscreen
.m-notification--fullscreen {
  left: 0;
  margin: auto;
  max-width: 768px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
