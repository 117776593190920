html {
  color: #000;
  background: #FFF;
}

body,
div,
dl, dt, dd,
ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, code,
form, fieldset, legend, input, textarea,
p, blockquote,
table, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, optgroup, option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input, textarea, select, optgroup, option {
  *font-size: 100%;
}

legend {
  color: #000;
}

@font-face {
  font-family: "FacitWeb";
  src: url("../font/FacitWeb.eot");
  src: url("../font/FacitWeb.eot?#iefix") format("embedded-opentype"), url("../font/FacitWeb.woff") format("woff"), url("../font/FacitWeb.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "FacitWeb";
  src: url("../font/FacitWeb-Bold.eot");
  src: url("../font/FacitWeb-Bold.eot?#iefix") format("embedded-opentype"), url("../font/FacitWeb-Bold.woff") format("woff"), url("../font/FacitWeb-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "FontAwesome";
  src: url("../font/fontawesome-webfont.eot?v=4.1.0");
  src: url("../font/fontawesome-webfont.eot?#iefix&v=4.1.0") format("embedded-opentype"), url("../font/fontawesome-webfont.woff?v=4.1.0") format("woff"), url("../font/fontawesome-webfont.ttf?v=4.1.0") format("truetype"), url("../font/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
}

html {
  background: #FDFCFC;
  font-size: 16px;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body {
  color: #4F4E55;
  font-family: "FacitWeb", Helvetica, sans-serif;
  font-size: 13px;
  line-height: 1.35;
  font-feature-settings: "liga", "kern";
  text-rendering: optimizeLegibility;
}
a {
  color: #5F6FFF;
  text-decoration: none;
}

:focus {
  outline: none;
}

input {
  background: #FDFCFC;
}

input[type=radio] {
  margin: 0 1px;
}

select {
  font-size: 14px;
}

input,
textarea {
  font-family: "FacitWeb", Helvetica, sans-serif;
}

button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
  border: none;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type=checkbox],
input[type=radio],
input[type=file] {
  background: transparent;
}

::-ms-clear {
  display: none;
}

iframe {
  border: 0;
}

hr {
  border: 1px solid #B0ACAE;
  border-top: none;
}

input[type=text]:focus,
input[type=password]:focus,
textarea:focus,
select:focus {
  outline: 2px solid #5F6FFF;
}
.case-interview-field-focused input[type=text]:focus,
.case-interview-field-focused input[type=password]:focus,
.case-interview-field-focused textarea:focus,
.case-interview-field-focused select:focus {
  outline: none;
}

input[type=text],
input[type=password],
textarea {
  -webkit-appearance: none;
}

.ui-display-none,
.app-a11y-label {
  display: none !important;
}

.ui-invisible,
.yui3-overlay-hidden {
  visibility: hidden !important;
}

body,
html {
  background: #f7f7f7;
}

.m-notification {
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.m-notification a {
  color: #5F6FFF;
}

.m-notification__heading {
  margin-bottom: 12px;
  font-size: 42px;
}

.m-notification__keyvisual {
  display: block;
  margin: 0 auto 20px;
  height: 292px;
  width: 272px;
  background: url(../img/404.png) no-repeat;
}

.m-notification--server-error .m-notification__keyvisual {
  background-image: url(../img/500.png);
}

.m-notification--startup .m-notification__keyvisual {
  height: 362px;
  opacity: 0.6;
  position: relative;
  width: 450px;
  background-image: url(../img/money.png);
}
.m-notification--startup .m-notification__keyvisual:before {
  content: "";
  height: 85px;
  left: 50%;
  margin-top: 140px;
  position: absolute;
  top: 50%;
  width: 85px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: url(../img/spinner.gif) no-repeat #f7f7f7;
  background-size: 100%;
}

.m-notification--blocked .m-notification__keyvisual {
  background-image: url(../img/403.png);
}

.m-notification--fullscreen {
  left: 0;
  margin: auto;
  max-width: 768px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}